
@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@edx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

@import "~@edx/frontend-component-header/dist/index";
@import "~@edx/frontend-component-footer/dist/footer";

@import "./order-history/style";
@import "./orders-and-subscriptions/style";

.word-break-all {
  word-break: break-all !important;
}

// TODO: Update edx-bootstrap theme to incorporate these edits.
.btn, a.btn {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.btn-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & > main {
    flex-grow: 1;
  }
}
