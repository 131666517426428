.page__order-history {
  table {
    thead th,
    tbody td {
      width: 4em;
    }

    thead tr th:first-child,
    tbody tr td:first-child {
      width: 12em;
    }
  }
}
.pagination-margin {
  margin-top: 1rem;
}
